// 操作本地存储
// 封装本地存储操作模块

// 存储数据
export const setItem = (key, value) => {
    if (typeof value === 'object') { //如果数据是数组或者对象，将其转换为json字符串
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, value)
}

// 读取数据
export const getItem = key => {
    // return window.localStorage.getItem(key) 
    const data = window.localStorage.getItem(key)
        // 得到的有可能是JSON格式的字符串，需要转换
    try {
        //如果执行这一步成功，证明是json字符串，将其转换为对象或者数组JSON.parse()
        return JSON.parse(data)
    } catch (err) {
        // 如果上面执行失败，证明不是json字符串，直接返回数据就行
        return data
    }
}

// 删除数据
export const removeItem = key => {
    window.localStorage.removeItem(key)
}