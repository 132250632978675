<template>
  <div class="wrap">
    <div class="wrap_bg">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss">
// @import url('/assets/css/style.css');
</style>
