import { createStore } from 'vuex'
import { setItem, getItem, removeItem } from '@/utils/storage.js'
// 定义本地存储中的键名 ，如果写错键名会报错提示，如果直接写字符串不会报错提示
const TOKEN_KEY = 'LOGIN_USER'
const TOKEN_CODE ='SMS_CODE'
export default createStore({
  state: {
    // user 一个对象，用来存储用户信息，token等数据
    // user:null
    user: getItem(TOKEN_KEY), // 这样刷新也不会丢失
    sms: getItem(TOKEN_CODE),
  },
  mutations: {
    // 修改state数据
    setUser(state, data) {
      state.user = data //当传递进来当数据为null的时候，就相当于清除数据来

      // 为了防止刷新丢失，我们需要把数据备份到本地存储
      // window.localStorage.setItem(TOKEN_KEY, JSON.stringify(state.user))
      setItem(TOKEN_KEY, state.user) //当传递进来当数据为null的时候，就相当于清除数据来
    },
    setSms(state,data){
      state.sms=data
      setItem(TOKEN_CODE, state.sms)
    }
  },
  actions: {
  },
  modules: {
  }
})
