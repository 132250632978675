import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/gifts',
    name: 'gifts',
    component: () => import('@/views/gifts/index.vue')
  },
  {
    path: '/type',
    name: 'type',
    component: () => import('@/views/type/index.vue')
  },
  {
    path: '/user',
    name: "user",
    component: () => import('@/views/user/index.vue')
  },
  {
    path: '/gamedetail/:gameId',
    name: 'gamedetail',
    component: () => import('@/views/gamedetail/index.vue'),
    // 将动态路由参数映射到组件的 props 中
    props: true,
  },
  {
    path: '/newsdetail/:newsId',
    name: 'newsdetail',
    component: () => import('@/views/news/detail.vue'),
    // 将动态路由参数映射到组件的 props 中
    props: true,
  },


  {
    path: '/banner',
    name: 'banner',
    component: () => import('../views/banner/index.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
